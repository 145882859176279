import { render, staticRenderFns } from "./mallOrderList.vue?vue&type=template&id=4733b6b8&scoped=true"
import script from "./mallOrderList.vue?vue&type=script&lang=js"
export * from "./mallOrderList.vue?vue&type=script&lang=js"
import style0 from "./mallOrderList.vue?vue&type=style&index=0&id=4733b6b8&prod&lang=less&scoped=true"
import style1 from "./mallOrderList.vue?vue&type=style&index=1&id=4733b6b8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4733b6b8",
  null
  
)

export default component.exports